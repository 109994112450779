<template>
  <select class="form-select" :id="inputId" :required="!notRequired" :disabled="disabled" :readonly="disabled" v-model="internalValue" @change="onChange">
    <option v-for="option in options" :disabled="option.disabled" :value="option[optionValue]" :key="option[optionValue]">{{ option[optionLabel] }}</option>
  </select>
</template>

<script>

import formSelectMixin from './mixins/formSelect.js';

export default {
  mixins: [formSelectMixin],
};

</script>
